<template>
  <div style="padding-bottom: 300px">
    <template v-if="view_permission">
      <b-card v-if="is_admin">
        <table-search
          ref="search"
          :fields="search_fields"
          :default="search_default"
          :showBtn="false"
          @runSearch="runSearch"
          @changeField="searchChangeField"
        />
      </b-card>

      <b-card>
        <edit-field-header
          :title="title"
          :show-back-btn="false"
          :sub-title="$t('device_raw_data.warning_message')"
          sub-title-class="text-danger"
        />
        <edit-field
          :fields="fields"
          :rowData="rowData"
          post-api="/callbell/DownloadRawData"
          finish-router="deviceRawData"
          :submitBtnText="$t('device_raw_data.export_data')"
          :submitAction="submitAction"
          @beforeSubmit="beforeSubmit"
        >
          <template #bed="scope">
            <slot-bed
              ref="slot_bed"
              :row-data="rowData"
              :facility-id="rowData.facilityID"
              @addBed="addBed"
              @changeBedFacilityId="changeBedFacilityId"
              :slotConfig="fields[0].slot_config"
            />
          </template>
        </edit-field>
      </b-card>

      <modal-bed
        ref="model_bed"
        @changeBed="changeBed"
        :title="$t('device_raw_data.modal_bed_title')"
      />
    </template>
    <b-card v-else>{{ $t('permission.no_view') }}</b-card>

  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import SlotBed from '@/views/group/SlotBed'
import ModalBed from '@/views/group/ModalBed'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import fun from '@/views/group/fun.js'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'Index',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    SlotBed,
    ModalBed,
    EditField,
    EditFieldHeader,
    TableSearch,
  },
  data() {
    return {
      title: common.getMenuName('callBellRawDown'),
      view_permission: common.checkPermission('CallBell_Menu_RawDown'),
      // view_permission: false,
      is_admin: common.isAdmin(),
      company_options: [],
      facility_options: [],
      show_report_type: 'all',
      time_option: [],
      // company_id: common.getCompanyId(),
      // facility_id: common.getFacilityId(),

      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId()
      },

      // 表單的默認信息
      rowData: {
        facilityID: common.getFacilityId(),
        beds: []
      },

      fields: [
        {
          label: 'common.bed',
          name: 'bedIDs',
          type: 'slot',
          slot: 'bed',
          rule: 'required',
          validate_in_slot: true,
          slot_config: {
            name: 'bedID',
            label: 'common.bed',
            rule: 'required',
          }
        },
        {
          label: 'device_report.start_date',
          name: 'startDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(-6, 'days')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        },
        {
          label: 'device_report.end_date',
          name: 'endDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(1, 'hours')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        }
      ]
    }
  },

  mounted() {
    if (common.isAdmin()) {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
    }
    // set default value from fields to forms
    this.fields.forEach((field) => {
      if (field.default !== undefined && field.name) {
        this.$set(this.rowData, field.name, field.default)
      }
    })
  },

  methods: {
    handleDateChange({dateType, dateStr}) {
      const fields = this.fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 30,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.rowData,
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    addBed: function () {
      fun.addBed(this)
      this.$refs.model_bed.checkSelectAll()
    },
    changeBed: function (type, option) {
      fun.changeBed(this, type, option)
    },

    beforeSubmit: function (callback) {
      const res_1 = this.$refs.slot_bed ? this.$refs.slot_bed.buildData() : true
      callback(res_1)
    },

    changeBedFacilityId: function (facilityID) {
      this.$refs.model_bed.changeFacilityId(facilityID)
    },

    submitAction: function (rowData, api, _finishRouter) {

      // check date
      const startMoment = this.$moment(`${this.rowData['startDate']}`)
      const endMoment = this.$moment(`${this.rowData['endDate']}`)
      if (endMoment.diff(startMoment) < 0) {
        common.showError('ReportConditionDateError')
        return false
      }

      common.apiDownloadData({
        url: api,
        method: 'post',
        timeout: 2 * 60 * 60 * 1000,
        data: {
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          facilityID: rowData.facilityID,
          bedIDs: rowData.bedIDs,
        },
      })
    },

    runSearch() {
    },

    async searchChangeField(field, value) {
      const idMap = await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: true
      })
      this.rowData.facilityID = idMap.facilityID
      this.changeBedFacilityId(idMap.facilityID)
      this.$refs.slot_bed.clearBed()
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 2rem 0;
}

.reportWrapper:last-of-type > hr {
  display: none;
}
</style>

